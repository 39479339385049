const state = {
  payouts: null,
  partners:null,
  commissions:null,
  monthly:null,
  overall:null,
  buy_sell_credits:null,
  cashout:null,
  deposit:null,
  casino_bet_commissions:null,
  monthly_deposit:null,
  monthly_cashouts:null,
  depositapproved: null,
  cashoutapproved: null,
};
export default state;
