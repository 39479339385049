import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import admin from "./modules/admin";
import agents from "./modules/agents";
import mmcsr from "./modules/mmcsr";
import affiliates from "./modules/affiliates";
import user from "./modules/user"

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    admin,
    agents,
    mmcsr,
    affiliates,
    user
  },
  strict: debug,
  plugins: [],
});
