const actions = {
  async getAllAffiliates({ commit }) {
    try {
      const response = await this._vm.$affiliateApi.get(
        "/affiliate/affiliates"
      );
      const { data } = response;
      commit("SET_ALL_PARTNERS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPayoutHistory({ commit }, payload) {
    try {
      const response = await this._vm.$affiliateApi.post(
        "/affiliate/payouts",
        {
          user_id: payload && payload.user_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_PAYOUTS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getDeposits({ commit }, payload) {
    try {
      const response = await this._vm.$affiliateApi.post(
        "/affiliate/deposits",
        {
          voucher_code: payload && payload.voucher_codes,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;
      commit("SET_DEPOSITS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAffiliatesMonthly({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$affiliateApi.post(
          "/affiliate/monthly-data",
          {
            voucher_code: payload.voucher_code,
            date: payload.date,
          }
        );
        const { data } = response;

        commit("SET_MONTHLY", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getCasinoBetCommissions({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$affiliateApi.post(
          "/affiliate/bet-commissions-casino",
          {
            voucher_code: payload.voucher_code,
            user_id: payload.user_id,
          }
        );

        const { data } = response;

        commit("SET_CASINO_BET_COMMISSIONS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAffiliatesOverall({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$affiliateApi.post(
          "/affiliate/overall-data",
          {
            voucher_code: payload.voucher_code,
          }
        );
        const { data } = response;

        commit("SET_OVERALL", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getMonthlyCasinoBetCommissions({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$affiliateApi.post(
          "/affiliate/monthly-bet-commissions-casino",
          {
            voucher_code: payload.voucher_code,
            user_id: payload.user_id,
            date: payload.date,
          }
        );

        const { data } = response;

        commit("SET_MONTHLY_CASINO_BET_COMMISSIONS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },

  async getVoucherUsers({ commit }, payload) {
    try {
      const response = await this._vm.$affiliateApi.post(
        "/affiliate/voucher-users",
        {
          voucher_code: payload && payload.voucher_code,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;
      commit("SET_VOUCHER_USERS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },

  async getMonthlyNewVoucherUsers({ commit }, payload) {
    try {
      console.log("pppppayload", payload);
      const response = await this._vm.$affiliateApi.post(
        "/affiliate/monthly-voucher-users",
        {
          voucher_code: payload && payload.voucher_code,
          date: payload && payload.date,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;
      console.log("data fr", data);
      commit("SET_MONTHLY_NEW_VOUCHER_USERS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAffiliateProfile({ commit }) {
    try {
      const response = await this._vm.$affiliateApi.post(
        "/affiliate/main-data"
      );
      const { data } = response;
      commit("SET_AFFILIATE_PROFILE", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
};
export default actions;
