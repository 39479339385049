const getters = {
  user: ({ user }) => {
    return user;
  },
  doneTodos(state) {
    return "asdfasdf" + state.test;
  },
  payouts: (state) => {
    return state.payouts;
  },
};

export default getters;
