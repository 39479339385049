const mutations = {
  SET_PARTNERS: (state, payload) => {
    state.partner = payload;
  },
  SET_MATCHES: (state, payload) => {
    state.match = payload;
  },
  SET_TRANSACTIONS: (state, payload) => {
    state.transaction = payload;
  },
  SET_USERSDATA: (state, payload) => {
    state.usersdata = payload;
  },
  SET_MATCHSETTLED: (state, payload) => {
    state.matchsettled = payload;
  },
  SET_MATCHCOLLECTEDFEES: (state, payload) => {
    state.matchcollectedfees = payload;
  },
  SET_APPROVEDDEPOSIT: (state, payload) => {
    state.approveddeposit = payload;
  },
  SET_PARTNERSTRANSACTIONAPPROVED: (state, payload) => {
    state.partnertransactionapproved = payload;
  },
  SET_PARTNERSTOTALTRANSACTIONAPPROVED: (state, payload) => {
    state.partnerstotaltransactionapproved = payload;
  },
  SET_AGENTAFFILIATE_TRANSACTION_APPROVED_AMOUNT: (state, payload) => {
    state.agentaffiliateapprovedamount = payload;
  },
  SET_AGENTAFFILIATE_TRANSACTION_NUMBER_APPROVED_AMOUNT: (state, payload) => {
    state.agentaffiliatenumberapprovedamount = payload;
  },
  SET_DIRECTPARTNERSTOTALAPPROVED: (state, payload) => {
    state.directpartnerstotalapproved = payload;
  },
  SET_DIRECTPARTNERS_NUMBER_APPROVED: (state, payload) => {
    state.directpartnersnumberapproved = payload;
  },
  SET_MATCHES_SETTLED_PERDAY: (state, payload) => {
    state.matchsettledperday = payload;
  },
  SET_TOURNAMENT_SETTLED_PERDAY: (state, payload) => {
    state.tournamentsettledperday = payload;
  },
  SET_CASHOUTDIRECT_APPROVED_PERDAY: (state, payload) => {
    state.cashoutdirectapprovedperday = payload;
  },
  SET_DEPOSITDIRECT_APPROVED_PERDAY: (state, payload) => {
    state.depositdirectapprovedperday = payload;
  },
  SET_CASHOUTAGENT_APPROVED_PERDAY: (state, payload) => {
    state.cashoutagentapprovedperday = payload;
  },
  SET_DEPOSITAGENT_APPROVED_PERDAY: (state, payload) => {
    state.depositagentapprovedperday = payload;
  },
  // SET_AGENTAFFILIATENUMBERAPPROVED: (state, payload) => {
  //     state.agentaffilatenumberapproved = payload;
  // },
  SET_USERS: (state, payload) => {
    state.users = payload;
  },
  SET_USERS_ANALYTICS: (state, payload) => {
    state.analytics = payload;
  },
  SET_USERS_MATCHES: (state, payload) => {
    state.matchesanalytics = payload;
  },
  SET_USERS_LEAGUES: (state, payload) => {
    state.leaguesanalytics = payload;
  },
  SET_OVERALL_USERS_LEAGUES: (state, payload) => {
    state.leaguesoverallanalytics = payload;
  },
  SET_USERS_PARTNERS: (state, payload) => {
    state.partnersanalytics = payload;
  },
  SET_USERS_MATCHES_OVERALL: (state, payload) => {
    state.matchesoverall = payload;
  },
  SET_USER_STREAMER: (state, payload) => {
    state.streameranalytics = payload;
  },
  SET_USERS_PARTNER_OVERALL: (state, payload) => {
    state.partnersoverall = payload;
  },
  SET_USERS_PARTNER_OVERALL_BY_DATE: (state, payload) => {
    state.partnersoverallbydate = payload;
  },
  SET_USERS_MATCHES_OVERALL_BY_DATE: (state, payload) => {
    state.matchesoverallbydate = payload;
  },
  SET_USER_STREAMER_OVERALL: (state, payload) => {
    state.streamersoverall = payload;
  },
  SET_USERS_STREAMERS_OVERALL_BY_DATE: (state, payload) => {
    state.streamersoverallbydate = payload;
  },
  SET_LEAGUE_STREAMER: (state, payload) => {
    state.leaguestreamer = payload;
  },
  SET_USERS_LEAGUE_OVERALL_BY_DATE: (state, payload) => {
    state.leagueoverallbydate = payload;
  },
  SET_USERS_PARTNER_OVERALL_CASINO_BY_DATE: (state, payload) => {
    state.partnerscasinooverallbydate = payload;
  },
  SET_MATCHES_BETTOR_LINE: (state, payload) => {
    state.matchesbetsline = payload;
  },
  SET_ERROR: (state, payload) => {
    state.error = payload;
  },
  SET_COMMISSION_EARNED: (state, payload) => {
    state.commissionearned = payload;
  },
  SET_BND_BET: (state, payload) => {
    state.bndbets = payload;
  }
};
export default mutations;
