// import state from "@/store/modules/admin/state";
import Vue from 'vue';
import Router from 'vue-router';

/* application layouts here*/
const Dashboard = () => import('../layouts/Dashboard.vue');

/**layout pages here */
/**dashboard pages*/
const Login = () => import('../pages/login/login.vue');
//admin
const AdminOverview = () => import('../pages/admin/AdminOverview.vue');
const AdminGraph = () => import('../pages/admin/AdminGraph.vue');
// const AdminUserManagement = () => import("../pages/admin/AdminUserManagement.vue");
const AdminUserAnalytics = () => import('../pages/admin/AdminUserAnalytics.vue');
// const AdminMatches = () => import("../pages/admin/AdminMatches.vue");
const AdminStreamerLayouts = () => import('../pages/admin/AdminStreamerLayouts.vue');
const AdminPartnerLayouts = () => import('../pages/admin/AdminPartnerLayouts.vue');
// const AdminLeague = () => import("../pages/admin/AdminLeague.vue");
const AdminMatchAnalytics = () => import('../pages/admin/AdminMatchAnalytics.vue');
//
const AdminAuditCasinoTransactionChecker = () => import('../pages/audit/TransactionChecker.vue');

//mmcsr
const MMCSROverview = () => import('../pages/mmcsr/MMCSROverview.vue');

// const AgentPartner = () => import("../pages/agent/AgentHome.vue");
const AgentPayoutHistory = () => import('../pages/agent/PayoutsHistory.vue');
const AgentsDeposits = () => import('../pages/agent/AgentsDeposits.vue');
const AgentsCashouts = () => import('../pages/agent/AgentsCashouts.vue');
const AgentBuySellCreditsHistory = () => import('../pages/agent/BuySellCreditsHistory.vue');
const AgentsCommissions = () => import('../pages/agent/AgentsCommissions.vue');
const AgentOverview = () => import('../pages/agent/AgentOverview.vue');

// const MMCSR = () => import("../pages/mmcsr/MMCSRHome.vue");

//affiliate
const AffiliateOverview = () => import('../pages/affiliate/AffiliateOverView.vue');
const AffiliatePayoutHistory = () => import('../pages/affiliate/PayoutsHistory.vue');
const AffiliateDeposits = () => import('../pages/affiliate/AffiliateDeposits.vue');

Vue.use(Router);

const router = new Router({
	routes: [
		{
			path: '/',
			name: 'Login',
			component: Login,
			meta: {
				guest: true,
				roles: []
			}
		},
		{
			path: '/admin',
			name: 'Admin',
			component: Dashboard,
			children: [
				{
					path: '/admin/admin-overview',
					name: 'AdminOverview',
					component: AdminOverview,
					meta: {
						guest: false,
						roles: ['admin']
					}
				},
				{
					path: '/admin/admin-graph',
					name: 'AdminGraph',
					component: AdminGraph,
					meta: {
						guest: false,
						roles: ['admin']
					}
				},
				// {
				//   path: "/admin/admin-user-management",
				//   name: "AdminUserManagement",
				//   component: AdminUserManagement,
				//   meta: {
				//     guest: false,
				//     roles: ["admin"],
				//   },
				// },
				{
					path: '/admin/admin-user-analytics',
					name: 'AdminUserAnalytics',
					component: AdminUserAnalytics,
					meta: {
						guest: false,
						roles: ['admin']
					}
				},
				{
					path: '/admin/admin-matches',
					name: 'AdminMatchAnalytics',
					component: AdminMatchAnalytics,
					meta: {
						guest: false,
						roles: ['admin']
					}
				},
				{
					path: '/admin/admin-partner',
					name: 'AdminPartner',
					component: AdminPartnerLayouts,
					meta: {
						guest: false,
						roles: ['admin']
					}
				},
				{
					path: '/admin/admin-streamers',
					name: 'AdminStreamers',
					component: AdminStreamerLayouts,
					meta: {
						guest: false,
						roles: ['admin']
					}
				},
				// {
				//   path: "/admin/admin-league",
				//   name: "AdminLeague",
				//   component: AdminLeague,
				//   meta: {
				//     guest: false,
				//     roles: ["admin"],
				//   },
				// },
				{
					path: '/admin/audit/casino-transaction-checker',
					name: 'AdminAuditCasinoTransactionChecker',
					component: AdminAuditCasinoTransactionChecker,
					meta: {
						guest: false,
						roles: ['admin']
					}
				}
			]
		},
		{
			path: '/mmcsr',
			name: 'MMCSR',
			component: Dashboard,
			children: [
				{
					path: '/mmcsr/mmcsr-overview',
					name: 'MMCSROverview',
					component: MMCSROverview,
					meta: {
						guest: false,
						roles: ['admin']
					}
				}
			]
		},
		{
			path: '/agent-partner',
			name: 'AgentPartner',
			component: Dashboard,
			children: [
				{
					path: '/agent-partner/payout-history',
					name: 'AgentPayoutHistory',
					component: AgentPayoutHistory,
					meta: {
						guest: false,
						roles: ['user', 'partner', 'admin']
					}
				},
				{
					path: '/agent-partner/agent-deposits',
					name: 'AgentsDeposits',
					component: AgentsDeposits,
					meta: {
						guest: false,
						roles: ['user', 'partner', 'admin']
					}
				},
				{
					path: '/agent-partner/agent-cashouts',
					name: 'AgentsCashouts',
					component: AgentsCashouts,
					meta: {
						guest: false,
						roles: ['user', 'partner', 'admin']
					}
				},
				{
					path: '/agent-partner/agent-buysell-credits-history',
					name: 'AgentBuySellCreditsHistory',
					component: AgentBuySellCreditsHistory,
					meta: {
						guest: false,
						roles: ['user', 'partner', 'admin']
					}
				},
				{
					path: '/agent-partner/agent-commissions',
					name: 'AgentsCommissions',
					component: AgentsCommissions,
					meta: {
						guest: false,
						roles: ['user', 'partner', 'admin']
					}
				},
				{
					path: '/agent-partner/agent-overview',
					name: 'AgentOverview',
					component: AgentOverview,
					meta: {
						guest: false,
						roles: ['user', 'partner', 'admin']
					}
				}
			]
		},
		{
			path: '/affiliate-partner',
			name: 'AffiliatePartner',
			component: Dashboard,
			children: [
				{
					path: '/affiliate-partner/affiliate-overview',
					name: 'AffiliateOverview',
					component: AffiliateOverview,
					meta: {
						guest: false,
						roles: ['user', 'affiliate', 'admin']
					}
				},
				{
					path: '/affiliate-partner/affiliate-payout-history',
					name: 'AffiliatePayoutHistory',
					component: AffiliatePayoutHistory,
					meta: {
						guest: false,
						roles: ['user', 'affiliate', 'admin']
					}
				},
				{
					path: '/affiliate-partner/affiliate-deposits',
					name: 'AffiliateDeposits',
					component: AffiliateDeposits,
					meta: {
						guest: false,
						roles: ['user', 'affiliate', 'admin']
					}
				}
			]
		}
	],
	mode: 'history'
});

router.beforeEach(async (to, from, next) => {
	let hasjwt = router.app.$session.has('jwt');
	if (hasjwt) {
		let meta_role = to.meta.roles;
		let role = router.app.$session.get('user').permissions;
		console.log('role', role);
		console.log('session', router.app.$session.get('user'));
		if (role == 'admin' && meta_role.includes(role)) {
			next(); //admin
		} else if (router.app.$session.get('user').permissions == 'matchmanager') {
			next(); //matchmanager
		} else if (
			!router.app.$session.get('user').is_partner &&
			router.app.$session.get('user').voucher_code != null &&
			meta_role.includes('affiliate')
		) {
			next(); //affiliate
		} else if (
			router.app.$session.get('user').is_partner &&
			router.app.$session.get('user').voucher_code == null &&
			meta_role.includes('partner')
		) {
			next(); //partner
		} else {
			router.app.$session.destroy();
			window.location.href = '/';
		}
	} else {
		if (to.path != '/') {
			next({
				path: '/'
			});
		} else {
			next();
		}
	}
});

export default router;
