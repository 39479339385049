const actions = {
	async getMatchesData({ commit }) {
		try {
			const response = await this._vm.$userApi.get(`/admin-overview`);
			const { data } = response;
			commit('SET_MATCHES', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getPartnersData({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/partners/data`);
			const { data } = response;
			commit('SET_PARTNERS', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getTransactionsData({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/transactions/data`);
			const { data } = response;
			commit('SET_TRANSACTIONS', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getUsersData({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/data`);
			const { data } = response;
			commit('SET_USERSDATA', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Matches and Fees Graph
	//Number of Matches settled (CS:GO vs Dota2 vs NBA vs Others)
	async getMatchesSettledGraph({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/matches-settled`);
			const { data } = response;
			commit('SET_MATCHSETTLED', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Matches Fees Collected (CS:GO vs Dota2 vs NBA vs Others)
	async getMatchesCollectedFees({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/match-fees`);
			const { data } = response;
			commit('SET_MATCHCOLLECTEDFEES', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	//Deposit and Cashouts Graph
	//Approved Deposits Amount MOPs (BDO vs BPI vs SB vs MB vs Remittance) direct
	async getApprovedDeposit({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/transaction-mop`);
			const { data } = response;
			commit('SET_APPROVEDDEPOSIT', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	//Partner Transactions Graph
	//Transactions Approved  Amount (jop vs cbb vs jayAR vs OGcredits)
	async getAmountTransactionApproved({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/amount-transaction-approved`);
			const { data } = response;

			commit('SET_PARTNERSTRANSACTIONAPPROVED', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Number of Transactions Approved (jop vs cbb vs jayAR vs OGcredits)
	async getTotalTransactionApproved({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/total-transaction-approved`);
			const { data } = response;
			commit('SET_PARTNERSTOTALTRANSACTIONAPPROVED', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	//AGENT AND AFFILIATE TRANSACTION GRAPH
	//Transactions Approved  Amount (affiliates vs agents)
	async getAgentAffiliateTransactionsApprovedAmount({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/agent-affiliate-amount`);
			const { data } = response;
			commit('SET_AGENTAFFILIATE_TRANSACTION_APPROVED_AMOUNT', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Number of Transactions Approved (affiliates vs agents)
	async getAgentAffiliateNumberApprovedAmount({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/agent-affiliate-total`);
			const { data } = response;
			commit('SET_AGENTAFFILIATE_TRANSACTION_NUMBER_APPROVED_AMOUNT', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	//DIRENCT AND PARTNERS TRANSACTION GRAPH
	//Transactions Approved  Amount (direct vs partners)
	async getDirectPartnersTotalApproved({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/direct-agent-amount`);
			const { data } = response;
			commit('SET_DIRECTPARTNERSTOTALAPPROVED', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	// Number of Transactions Approved (direct vs partners)
	async getDirectPartnersNumberApproved({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/pie/direct-agent-total`);
			const { data } = response;
			commit('SET_DIRECTPARTNERS_NUMBER_APPROVED', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Total matches settled per day
	async getMatchesSettledPerDay({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/matches-settled`);
			const { data } = response;
			commit('SET_MATCHES_SETTLED_PERDAY', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	// Total tournament settled per day
	async getTournamentSettledPerDay({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/leagues-settled`);
			const { data } = response;
			commit('SET_TOURNAMENT_SETTLED_PERDAY', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Graph of Total approved cashout per day  (direct)
	async getCashoutApprovedPerDayDirect({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/cashout`);
			const { data } = response;
			commit('SET_CASHOUTDIRECT_APPROVED_PERDAY', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Graph of Total approved deposits per day (direct)
	async getDepositApprovedPerDayDirect({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/deposit`);
			const { data } = response;
			commit('SET_DEPOSITDIRECT_APPROVED_PERDAY', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	// Graph of Total approved cashout per day  (agents)
	async getCashoutApprovedPerDayAgent({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/agent-cashout`);
			const { data } = response;
			commit('SET_CASHOUTAGENT_APPROVED_PERDAY', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	//Graph of Total approved deposits per day (agents)
	async getDepositApprovedPerDayAgent({ commit }) {
		try {
			const response = await this._vm.$adminApi.get(`/users/graph-data/agent-deposit`);
			const { data } = response;
			commit('SET_DEPOSITAGENT_APPROVED_PERDAY', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	async getUsersManagement({ commit }) {
		try {
			const response = await this._vm.$adminApi.get('/users');
			const { data } = response;
			commit('SET_USERS', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	async getUserAnalytics({ commit }, payload) {
		try {
			const u = new URLSearchParams(payload).toString();
			const response = await this._vm.$userApi.get('/users?'+u);
			const data = response.data.users;
			commit('SET_USERS_ANALYTICS', data);
			return response.data;
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

  async getUserMatches({ commit }, payload) {
    try {
      const u = new URLSearchParams(payload).toString();
      const response = await this._vm.$userApi.get("/matches?"+u);
      const data = response.data.matches;

      commit("SET_USERS_MATCHES", data);
      return response.data.count;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },

  async getUserLeagues({ commit }, payload) {
    try {
      const u = new URLSearchParams(payload).toString();
      const response = await this._vm.$userApi.get("/leagues?"+u);
      const data  = response.data.leagues;
      
      commit("SET_USERS_LEAGUES", data);
      return response.data.count;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getOverallUserLeagues({ commit }, payload) {
    try {
      const response = await this._vm.$userApi.post("/leagues/overall", {
        type: payload && payload.type,
      });
      const { data } = response;
      commit("SET_OVERALL_USERS_LEAGUES", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getUserPartners({ commit }, payload) {
    try {
		const u = new URLSearchParams(payload).toString();
		const response = await this._vm.$userApi.get("/agents?"+u);
		const data  = response.data.partners;
		commit("SET_USERS_PARTNERS", data);
		return response.data.count;
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPartnersOverall({ commit }, payload) {
    try {
      const response = await this._vm.$userApi.post("/agents/overall", {
        type: payload && payload.type,
      });
      const { data } = response;

			commit('SET_USERS_PARTNER_OVERALL', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getPartnersOverallByDate({ commit }, payload) {
		try {
			if(!payload){
				const response = await this._vm.$userApi.post('/agents/overall/by-date', {
					date: false
				});
				const { data } = response;
				console.log('data 1 ', data);
	
				commit('SET_USERS_PARTNER_OVERALL_BY_DATE', data);
			}else{
				const response = await this._vm.$userApi.post('/agents/overall/by-date', {
					date: {
					startDate: payload && payload.startDate,
					endDate: payload && payload.endDate
					}
				});
				const { data } = response;
				console.log('data 2', data);
	
				commit('SET_USERS_PARTNER_OVERALL_BY_DATE', data);
			}
			
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getPartnersOverallCasinoByDate({ commit }, payload) {
		try {
			if(!payload){
				const response = await this._vm.$userApi.post('/agents/overall/casino/by-date', {
					date: false
				});
				const { data } = response;
				console.log('data 1 ', data);
	
				commit('SET_USERS_PARTNER_OVERALL_CASINO_BY_DATE', data);
			}else{
				const response = await this._vm.$userApi.post('/agents/overall/casino/by-date', {
					date: {
					startDate: payload && payload.startDate,
					endDate: payload && payload.endDate
					}
				});
				const { data } = response;
				console.log('data 2', data);
	
				commit('SET_USERS_PARTNER_OVERALL_CASINO_BY_DATE', data);
			}
			
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getMatchesOverall({ commit }, payload) {
		try {
			const response = await this._vm.$userApi.post('/matches/overall', {
				type: payload && payload.type
			});
			const { data } = response;

			commit('SET_USERS_MATCHES_OVERALL', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getMatchesOverallByDate({ commit }, payload) {
		try {
			if(!payload){
				const response = await this._vm.$userApi.post('/matches/overall/by-date', {
					date: false
					
				});
				const { data } = response;
				commit('SET_USERS_MATCHES_OVERALL_BY_DATE', data);
			}else{
				const response = await this._vm.$userApi.post('/matches/overall/by-date', {
					date: {
						startDate: payload && payload.startDate,
						endDate: payload && payload.endDate
					}
				});
				const { data } = response;
				commit('SET_USERS_MATCHES_OVERALL_BY_DATE', data);
			}
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getUserStreamer({ commit }) {
		try {
			const response = await this._vm.$userApi.get('/affilates');
			const { data } = response;

			commit('SET_USER_STREAMER', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getSteamersOverall({ commit }, payload) {
		try {
			console.log('payload', payload && payload.filter);

			const response = await this._vm.$userApi.post('/users/affiliates-overall', {
				filter: payload && payload.filter
			});
			const { data } = response;
			console.log('payload', data);

			commit('SET_USER_STREAMER_OVERALL', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getSteamersOverallByDate({ commit }, payload) {
		try {
			const response = await this._vm.$userApi.post('/users/affiliates-overall/by-date', {
				startDate: payload && payload.startDate,
				endDate: payload && payload.endDate
			});
			const { data } = response;

			commit('SET_USERS_STREAMERS_OVERALL_BY_DATE', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getLeagueStreamer({ commit }, payload) {
		try {
			const { id } = payload;
			console.log('payload payload', id);
			const response = await this._vm.$userApi.get(`/leagues/data/${id}`);
			// const response = await this._vm.$userApi.get('/leagues/data/',{
			//   params: id
			// });
			const { data } = response;
			console.log('dataxxx', data);
			commit('SET_LEAGUE_STREAMER', data);
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},
	async getLeagueOverallByDate({ commit }, payload) {
		try {
			if(!payload){
				const response = await this._vm.$userApi.post('/leagues/overall/by-date', {
					date : false
				});
				const { data } = response;
				commit('SET_USERS_LEAGUE_OVERALL_BY_DATE', data);
			}else{
				const response = await this._vm.$userApi.post('/leagues/overall/by-date', {
					date : {
						startDate: payload && payload.startDate,
						endDate: payload && payload.endDate
					}
				});
				const { data } = response;
				commit('SET_USERS_LEAGUE_OVERALL_BY_DATE', data);
			}
			
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	async uploadSlotegatorCsvFile({ commit }, payload) {
		try {
			const headers = { 'Content-Type': 'multipart/form-data' };
			const response = await this._vm.$adminApi.post(`/audit/upload-casino-csv`, payload, { headers });
			const { data } = response;
			return data;
		} catch (error) {
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	async getCommissionEarnedPartner({ commit }, payload){
		try {
			const { id } = payload;
			console.log("commission payload", id);
			const response = await this._vm.$userApi.get(`/matches/commissions/data/${id}`);
			const { data } = response;
			commit('SET_COMMISSION_EARNED', data);
		} catch(error){
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	async getMatchesBettorLineGraph({ commit }){
		try{
			const response = await this._vm.$adminApi.get(`/users/graph-data/bettors`)
			const { data } = response;
			commit("SET_MATCHES_BETTOR_LINE", data);
		} catch(error){
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	},

	async getBNDbet({ commit }){
		try{
			const response = await this._vm.$adminApi.get(`/users/graph-data/bnd`)
			const { data } = response;
			commit("SET_BND_BET", data);
		} catch(error){
			console.log('error', error);
			commit('SET_ERROR', error);
		}
	}
};
export default actions;
