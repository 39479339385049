const mutations = {
    login(state, user) {
        state.user = user;
    },
    logout(state) {
        state.user = null;
    },
    setError: (state, payload) => {
        state.error = payload;
      },
    loginFailed(state, error) {
        state.error = error;
    },
    stopLoading(state) {
        state.loading = false;
    },
    resetError(state) {
        state.error = false;
    },
    isLoading(state) {
        state.loading = true;
    },
}
export default mutations;