const formatDate = (date) => {
    const currentDate = new Date(date);
    return currentDate.toLocaleDateString('en-US');
  };
  
  const formatDateWithTime = (date) => {
    const dateSplit = date.split(' ');
    const currentDate = new Date(`${dateSplit[0].replace(/-/g, '/')} ${dateSplit[1]}`);
  
    return currentDate.toLocaleString('en-US');
  };
  
  export { formatDate, formatDateWithTime };
  