import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import Vuex from "vuex";
import router from "./router/routes";
import store from "./store";
import { Chart } from "chart.js";
import Chartkick from "vue-chartkick";
import VueApexCharts from "vue-apexcharts"
import VueSession from "vue-session";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import {
  adminApi,
  mmcsrApi,
  agentsApi,
  affiliateApi,
  authApi,
  userApi,
} from "./helpers/api";

import { formatDate, formatDateWithTime } from "./helpers/filters/formatDate";
import {
  formatMoney,
  formatFee,
  formatNum,
} from "./helpers/filters/formatNumber";
import { formatTags } from "./helpers/filters/formatJson";
import "regenerator-runtime";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

Vue.config.productionTip = false;

//set api
Vue.prototype.$adminApi = adminApi;
Vue.prototype.$mmcsrApi = mmcsrApi;
Vue.prototype.$agentsApi = agentsApi;
Vue.prototype.$affiliateApi = affiliateApi;
Vue.prototype.$authApi = authApi;
Vue.prototype.$userApi = userApi;

//set filters
Vue.filter("formatMoney", formatMoney);
Vue.filter("formatFee", formatFee);
Vue.filter("formatTags", formatTags);
Vue.filter("formatDate", formatDate);
Vue.filter("formatDateWithTime", formatDateWithTime);
Vue.filter("formatNum", formatNum);

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Chartkick.use(Chart));
Vue.use(VueApexCharts);
Vue.use(VueSession, { persist: true });
Vue.use(DateRangePicker)


// eslint-disable-next-line 
/* eslint-disable */
Vue.component('apexchart', VueApexCharts)

Vue.prototype.moment = moment;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
