const localStorageUser = (() => {
    const state = JSON.parse(localStorage.getItem('vue-session-key'));
    return !!state === true ? state.user : null;
  })();

  const formatTags = tags => {
    let formatTags = '';
    if (tags == 'tiny') {
      formatTags = 'Tiny Bettor';
    } else if (tags == 'small') {
      formatTags = 'Small Bettor';
    }  else if (tags == 'normal') {
      formatTags = 'Normal Bettor';
    }
    else if (tags == 'big_bettor') {
      formatTags = 'Big Bettor';
    }
     else {
      formatTags = 'VIP High Roller';
    }
    return formatTags;
  };

  export {
    localStorageUser,
    formatTags
  }