const actions = {
  async getAllPartners({ commit }) {
    try {
      const response = await this._vm.$agentsApi.get("/agent-partner/partners");
      const { data } = response;
      commit("SET_ALL_PARTNERS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getPayoutHistory({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/payout-history",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );

      const { data } = response;

      commit("SET_PAYOUTS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAgentCommissions({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/transaction-commissions",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_COMMISSIONS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAgentMonthly({ commit }, payload) {
    try {
      console.log("payload brian", payload)
        const response = await this._vm.$agentsApi.post(
          "/agent-partner/monthly-data",
          {
            partner_id: payload ? payload.partner_id : 0,
            date: payload && payload.date,
            user_id: payload && payload.user_id,
          }
        );
        const { data } = response;
        console.log("data res", response);

        commit("SET_MONTHLY", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getAgentOverall({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$agentsApi.post(
          "/agent-partner/overall-data",
          {
            partner_id: payload.partner_id,
            user_id: payload.user_id,
          }
        );
        const { data } = response;

        commit("SET_OVERALL", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getBuySellCreditsHistory({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/buy-sell-credits",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_BUY_SELL_CREDITS", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getCashout({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/cashout",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_CASHOUT", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getDeposit({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/deposit",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_DEPOSIT", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getApprovedDeposit({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/approved/deposit",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_APPROVED_DEPOSIT", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getApprovedCashoutOverall({ commit }, payload) {
    try {
      const response = await this._vm.$agentsApi.post(
        "/agent-partner/tabs/approved/cashout",
        {
          partner_id: payload && payload.partner_ids,
        },
        {
          params: {
            page: payload && payload.page,
            pageSize: payload && payload.pageSize,
          },
        }
      );
      const { data } = response;

      commit("SET_APPROVED_CASHOUT", data);
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getCasinoBetCommissions({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$agentsApi.post(
          "/agent-partner/bet-commissions-casino",
          {
            partner_id: payload.partner_id,
            user_id: payload.user_id,
          }
        );

        const { data } = response;
        commit("SET_CASINO_BET_COMMISSIONS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getMonthlyDeposit({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$agentsApi.post(
          "/agent-partner/tabs/monthly-deposit",
          {
            partner_id: payload && payload.partner_ids,
            date: payload && payload.date,
          },
          {
            params: {
              page: payload && payload.page,
              pageSize: payload && payload.pageSize,
            },
          }
        );
        const { data } = response;

        commit("SET_MONTHLY_DEPOSIT", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
  async getMonthlyCashouts({ commit }, payload) {
    try {
      if (payload) {
        const response = await this._vm.$agentsApi.post(
          "/agent-partner/tabs/monthly-cashout",
          {
            partner_id: payload && payload.partner_ids,
            date: payload && payload.date,
          },
          {
            params: {
              page: payload && payload.page,
              pageSize: payload && payload.pageSize,
            },
          }
        );

        const { data } = response;

        commit("SET_MONTHLY_CASHOUTS", data);
      }
    } catch (error) {
      console.log("error", error);
      commit("SET_ERROR", error);
    }
  },
};
export default actions;
