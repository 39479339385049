const actions = {
    async login({commit}, payload){
        try {
            const response = await this._vm.$authApi.post('/users/auth',{
                email: payload.email,
                password: payload.password
            })
            const { data } = response;
            commit('login', data.user);
            commit('stopLoading');
            return data;
        }catch(error){
            console.log("errorss", error.response.data.error);
            commit('setError', error.response.data.error);
            commit('stopLoading');
        }
    },

    async fbLogin({commit}, payload){
        try {
          console.log("payload", payload);
            const response = await this._vm.$authApi.post('/users/login-fb', {
              auth: payload
            });
            const { data } = response;
            
            console.log("payload2222", data);
            if (data.success == true) {
              console.log('dataaaa', data.user)
              commit('login', data.user);
            } else {
              commit('setError', data.error);
            }
            commit('stopLoading');
            return data;
          } catch (error) {
            commit('loginFailed', error.response.data.error);
            commit('stopLoading');
          }
    }
}
export default actions;