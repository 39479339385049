const getters = {
  user: ({ user }) => {
    return user;
  },
  doneTodos(state) {
    return "asdfasdf" + state.test;
  },
  match: (state) => {
    return state.match;
  },
  partner: (state) => {
    return state.partner;
  },
  transaction: (state) => {
    return state.transaction;
  },
  usersdata: (state) => {
    return state.usersdata;
  },
  matchsettled: (state) => {
    return state.matchsettled;
  },
  matchcollectedfees: (state) => {
    return state.matchcollectedfees;
  },
  approveddeposit: (state) => {
    return state.approveddeposit;
  },
  partnertransactionapproved: (state) => {
    return state.partnertransactionapproved;
  },
  partnerstotaltransactionapproved: (state) => {
    return state.partnerstotaltransactionapproved;
  },
  agentaffiliateapprovedamount: (state) => {
    return state.agentaffiliateapprovedamount;
  },
  agentaffiliatenumberapprovedamount: (state) => {
    return state.agentaffiliatenumberapprovedamount;
  },
  directpartnerstotalapproved: (state) => {
    return state.directpartnerstotalapproved;
  },
  directpartnersnumberapproved: (state) => {
    return state.directpartnersnumberapproved;
  },
  matchsettledperday: (state) => {
    return state.matchsettledperday;
  },
  tournamentsettledperday: (state) => {
    return state.tournamentsettledperday;
  },
  cashoutdirectapprovedperday: (state) => {
    return state.cashoutdirectapprovedperday;
  },
  depositdirectapprovedperday: (state) => {
    return state.depositdirectapprovedperday;
  },
  cashoutagentapprovedperday: (state) => {
    return state.cashoutagentapprovedperday;
  },
  depositagentapprovedperday: (state) => {
    return state.depositagentapprovedperday;
  },
  analytics: (state) => {
    return state.analytics;
  },
  leaguesanalytics: (state) => {
    return state.leaguesanalytics;
  },
  leaguesoverallanalytics: (state) => {
    return state.leaguesoverallanalytics;
  },
  partnersanalytics: (state) => {
    return state.partnersanalytics;
  },
  streameranalytics: (state) => {
    return state.streameranalytics;
  },
  partnersoverall: (state) => {
    return state.partnersoverall;
  },
  partnersoverallbydate: (state) => {
    return state.partnersoverallbydate;
  },
  matchesoverallbydate: (state) => {
    return state.matchesoverallbydate;
  },
  streamersoverall: (state) => {
    return state.streamersoverall;
  },
  leaguestreamer: (state) => {
    return state.leaguestreamer;
  },
  partnerscasinooverallbydate: (state) => {
    return state.partnerscasinooverallbydate;
  }
};

export default getters;
