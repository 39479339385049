const formatMoney = (money) => {
  return !!money === true
    ? parseFloat(money)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : "0.00";
};

const formatFee = (fee) => {
  return !!fee === true ? parseFloat(fee * 100).toFixed(0) : "%";
};

function formatNum(num) {
  return !!num === true
    ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "0";
}

export { formatMoney, formatFee, formatNum };
