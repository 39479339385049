import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
    theme: {
      themes: {
        dark    : {
            primary: '#424242', // #E53935
            secondary: '#bdbdbd', // #FFCDD2
            accent: '#fbc02d', // #3F51B5
            yellow2ez: '#d4af37',
            danger: '#FF2E2E',
            statusGreen: '#00FF15',
            matchStatusOpen: '#5bc0de'
        },
      },
    },
  })
  export default vuetify
