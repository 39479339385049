const mutations = {
  SET_PAYOUTS: (state, payload) => {
    state.payouts = payload;
  },
  SET_ALL_PARTNERS: (state, payload) => {
    state.partners = payload;
  },
  SET_DEPOSITS: (state, payload) => {
    state.deposits = payload;
  },
  SET_MONTHLY: (state, payload) => {
    state.monthly = payload;
  },
  SET_OVERALL: (state, payload) => {
    state.overall = payload;
  },
  SET_CASINO_BET_COMMISSIONS: (state, payload) => {
    state.casino_bet_commissions = payload;
  },
  SET_MONTHLY_CASINO_BET_COMMISSIONS: (state, payload) => {
    state.casino_monthly_bet_commissions = payload;
  },
  SET_VOUCHER_USERS: (state, payload) => {
    state.voucherusers = payload;
  },
  SET_MONTHLY_NEW_VOUCHER_USERS: (state, payload) => {
    state.newvoucherusers = payload;
  },
  SET_AFFILIATE_PROFILE: (state, payload) => {
    state.profile = payload;
  },
  SET_ERROR: (state, payload) => {
    state.error = payload;
  },
};
export default mutations;
