const state = {
  match: null,
  partner: null,
  transaction: null,
  usersdata: null,
  matchsettled: null,
  matchcollectedfees: null,
  approveddeposit: null,
  partnertransactionapproved: null,
  partnerstotaltransactionapproved: null,
  agentaffiliateapprovedamount: null,
  agentaffiliatenumberapprovedamount: null,
  directpartnerstotalapproved: null,
  directpartnersnumberapproved: null,
  matchsettledperday: null,
  tournamentsettledperday: null,
  cashoutdirectapprovedperday: null,
  depositdirectapprovedperday: null,
  cashoutagentapprovedperday: null,
  depositagentapprovedperday: "test",
  users: null,
  analytics: null,
  matchesanalytics: null,
  leaguesanalytics: null,
  leaguesoverallanalytics: null,
  partnersanalytics: null,
  matchesoverall: null,
  streameranalytics: null,
  partnersoverall: null,
  partnersoverallbydate: null,
  matchesoverallbydate: null,
  streamersoverall: null,
  streamersoverallbydate: null,
  leaguestreamer: null,
  leagueoverallbydate: null,
  partnerscasinooverallbydate: null,
  commissionearned: null,
  matchesbetsline: null,
  bndbets: null

  // agentaffilatenumberapproved: null
};
export default state;
