import axios from "axios";
import { localStorageUser } from "../filters/formatJson";

const api = axios.create({ baseURL: process.env.VUE_APP_AFFILIATES_API_ENDPOINT });

if (!!localStorageUser === true)
  api.defaults.headers.common = {
    Authorization: `Bearer ${localStorageUser.token}`,
  };

export default api;
