const state = {
  payouts: null,
  partners: null,
  deposits: null,
  monthly: null,
  overall: null,
  casino_bet_commissions: null,
  casino_monthly_bet_commissions: null,
  voucherusers: null,
  newvoucherusers: null,
  profile: null,
};
export default state;
