const actions = {
    async getMMData({commit}){
        try{
            const response = await this._vm.$mmcsrApi.get('/users/data');
            const { data } = response;
            commit('SET_MMCSR', data)
        } catch(error){
            console.log("error", error);
            commit('SET_ERROR', error);
        }
    }
}
export default actions;