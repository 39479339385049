const mutations = {
  SET_PAYOUTS: (state, payload) => {
    state.payouts = payload;
  },
  SET_COMMISSIONS: (state, payload) => {
    state.commissions = payload;
  },
  SET_MONTHLY: (state, payload) => {
    state.monthly = payload;
  },
  SET_OVERALL: (state, payload) => {
    state.overall = payload;
  },
  SET_ALL_PARTNERS: (state, payload) => {
    state.partners = payload;
  },
  SET_BUY_SELL_CREDITS: (state, payload) => {
    state.buy_sell_credits = payload;
  },
  SET_CASHOUT: (state, payload) => {
    state.cashout = payload;
  },
  SET_DEPOSIT:(state, payload) => {
    state.deposit = payload;
  },
  SET_APPROVED_DEPOSIT:(state, payload) => {
    state.depositapproved = payload;
  },
  SET_APPROVED_CASHOUT:(state, payload) => {
    state.cashoutapproved = payload;
  },
  SET_CASINO_BET_COMMISSIONS:(state, payload) => {
    state.casino_bet_commissions = payload;
  },
  SET_MONTHLY_DEPOSIT:(state, payload) => {
    state.monthly_deposit = payload;
  },
  SET_MONTHLY_CASHOUTS:(state, payload) => {
    state.monthly_cashouts = payload;
  },
  SET_ERROR: (state, payload) => {
    state.error = payload;
  },
};
export default mutations;
